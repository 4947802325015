<template>
    <div class="orders">
      <div class="page-header">
        <h1>{{ $t('Delivery Order Management') }} > {{ $t('Delivery Invoices') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Delivery Invoices') }}</h2>
          <router-link :to="{ name: 'CreateDeliveryInvoices' }">
            <el-button class="action-button" type="primary">{{ $t('Add Delivery Invoices') }}</el-button>
          </router-link>
        </div>
        <div class="order-list">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ $t('Order No.') }}</th>
                <th scope="col">{{ $t('Date') }}</th>
                <th scope="col">{{ $t('Delivery Staff No.') }}</th>
                <th scope="col">{{ $t('Collection Fee') }}</th>
                <th scope="col">{{ $t('Actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invoice in invoices">
                <td>{{ invoice.reference_id }}</td>
                <td>{{ formatDateTime(invoice.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
                <td>{{ invoice.delivery_staff }}</td>
                <td>HK$ {{ invoice.collection_fee }}</td>
                <td>
                  <div class="action-links">
                    <router-link class="action-link" :to="{name:'DeliveryInvoice', params: {id: invoice.id}}">
                      {{ $t('Edit') }}
                    </router-link>
                    <a href="#" class="action-link danger" @click="deleteInvoice(invoice.id)">
                      {{ $t('Delete') }}
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import Common from '@/lib/common';
  import Delivery from '@/lib/delivery';
  import ItemFilter from '@/components/Filter.vue';
  
  export default {
    name: 'DeliveryInvoices',
    components: {
      ItemFilter,
    },
    mounted(){
      this.loadInvoices();
    },
    data(){
      return {
        invoices: [],
      }
    },
    methods:{
      formatDateTime(timestamp, format){
        return Common.formatDateTime(timestamp, format);
      },
      async loadInvoices(){
        try{
          const loginInfo = Common.getLoginInfo();
          let invoices = await Delivery.loadAllDeliveryOrders(this.apiUrl, loginInfo);
          this.invoices = invoices;
        }catch(err){
          console.log(err);
        }
      },
      async deleteInvoice(invoiceId){
        try{
          const loginInfo = Common.getLoginInfo();
          await Delivery.deleteOrder(this.apiUrl, invoiceId, loginInfo);
          const newInvoices = this.invoices.filter(function(item) {
            return item.id !== invoiceId;
          });
          this.invoices = newInvoices;
        }catch(err){
          console.log(err);
        }
      },
    },
    computed: mapState({
      langcode: state => state.langcode,
      apiUrl: state => state.apiUrl,
    }),
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  </style>
  